import axios from "axios";
import {getConfig} from "./get-config";

export async function getApiHttp() {
    let conf = await getConfig()

    return axios.create({
        baseURL: conf.apiUrl
    })
}
