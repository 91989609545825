import React from "react";
import {Stellplatz} from "./bearbeitung-eingabe";
import styles from "./bearbeitung-menue.module.scss"
import {AppBar, Button, Snackbar, Toolbar, Typography} from "@material-ui/core";
import {useMainStore} from "../hooks/use-main-store";
import {observer} from "mobx-react";
import Alert from "@material-ui/lab/Alert";

export let erfolg = true

export function setErfolg(e: boolean) {
    erfolg = e
}

export const BearbeitungMenue = observer(() => {
    const store = useMainStore();
    let stellplaetze = []
    const [open, setOpen] = React.useState(false);

    for (let pl of store.stellplaetze) {
        stellplaetze.push(
            <Stellplatz key={pl.nummer} store={pl}/>)
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let alert = []
    let dauer = 2000
    if (erfolg) {
        alert.push(
            <Snackbar key={'erfolg'}
                      open={open}
                      onClose={handleClose}
                      autoHideDuration={dauer}>
                <Alert onClose={handleClose} severity='success'>
                    Gespeichert
                </Alert>
            </Snackbar>)
    } else {
        alert.push(
            <Snackbar key={'fehler'}
                      open={open}
                      onClose={handleClose}
                      autoHideDuration={dauer}>
                <Alert onClose={handleClose} severity='error'>
                    Fehler
                </Alert>
            </Snackbar>)
    }


    return <div className={styles.root}>
        <Titelleiste/>
        <div className={styles.inhalt}>
            {stellplaetze}
        </div>
        <div className={styles.buttonContainer}>
            <Button disabled={!store.geaendert} color={'secondary'} variant={'contained'}
                    onClick={e => {
                        store.speichern(store.stellplaetze);
                        handleClick()
                    }}>Speichern</Button>
        </div>
        <div>
            {alert}
        </div>
    </div>
})

function Titelleiste() {
    return <AppBar position="sticky" className={styles.appBar} color='primary'>
        <Toolbar>
            <img src={process.env.PUBLIC_URL + "/wkt-logo.png"} className={styles.logo} alt={'WKT'}/>

            <div className={styles.ueberschrift}>
                <Typography variant="h6" align={'center'}>
                    Versandstellplätze
                </Typography>
            </div>
        </Toolbar>
    </AppBar>
}
