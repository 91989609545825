import React from 'react';
import './App.css';
import {BrowserRouter, Route} from "react-router-dom";
import {BearbeitungMenue} from "./components/bearbeitung-menue";
import {Anzeige} from "./components/anzeige";
import {deepPurple} from "@material-ui/core/colors";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import {mainStoreContext} from "./hooks/use-main-store";
import {MainStore} from "./model/main-store";


const theme1 = createMuiTheme({
    palette: {
        primary: {
            main: deepPurple.A700
        },
        secondary: {
            main: '#e6007e'
        },
        type: 'light'
    },

    overrides: {
        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: 14,
            },
        },
    }
})


const mainStore = new MainStore()

function App() {
    return (

        <ThemeProvider theme={theme1}>
            <mainStoreContext.Provider value={mainStore}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <Route path='/' exact={true} className="is-scrollLocked">
                        <BearbeitungMenue/>
                    </Route>
                    <Route path='/:nr'>
                        <Anzeige/>
                    </Route>
                </BrowserRouter>
        </mainStoreContext.Provider>
        </ThemeProvider>
    );
}

export default App;
