import {action, observable, runInAction} from "mobx";
import {getApiHttp} from "./util";

export class StellplatzStore {

    @observable text = ''
    @observable geladenerText = ''
    @observable laden = false

    constructor(public readonly nummer: number) {
        this.ladeText()
        setInterval(() => this.ladeText(), 60 * 1000)
    }

    @action
    async ladeText() {
        this.laden = true
        try {
            const http = await getApiHttp()
            const resp = await http.get<string>(String(this.nummer))
            let text = resp.data ?? ''
            if (this.text === this.geladenerText) {
                this.setText(text)
            }
            runInAction(() => this.geladenerText = text)
        } finally {
            runInAction(() => this.laden = false)
        }
    }

    @action
    setText(text: string) {
        this.text = text
    }
}
