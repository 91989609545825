import {StellplatzStore} from "./stellplatz-store";
import {computed} from "mobx";
import {getApiHttp} from "./util";
import {setErfolg} from "../components/bearbeitung-menue";

export class MainStore {

    stellplaetze: StellplatzStore[] = []

    constructor() {
        this.init()
    }

    init() {
        for (let i = 1; i <= 5; i++) {
            this.stellplaetze.push(new StellplatzStore(i))
        }
    }

    @computed
    get geaendert() {
        return this.stellplaetze.some(pl => pl.text !== pl.geladenerText)
    }

   private async onSpeichern(store: StellplatzStore) {
        const http = await getApiHttp()
        http.put(String(store.nummer), store.text).then(resp => {
            store.ladeText()
            if (resp.status === 200) {
                setErfolg(true)
            } else if (resp.status >= 400) {
                setErfolg(false)
                console.log('Fehler: '+resp.status )
            }else{
                console.log(resp.status)
            }
        })
    }

    speichern(store: StellplatzStore[]) {
        for (let platz of store) {
            if (platz.text !== platz.geladenerText) {
                this.onSpeichern(platz)
            }
        }
    }
}
