import axios from "axios";
import {Config} from "./config";

const baseURL = process.env.PUBLIC_URL + '/config.json'

const http = axios.create({
    baseURL
})

const promise = http.get<Config>('').then(r => r.data)

export async function getConfig() {
    return promise
}
