import React from "react";
import classes from "./bearbeitung-eingabe.module.scss";
import {TextField} from "@material-ui/core";
import {StellplatzStore} from "../model/stellplatz-store";
import {observer} from "mobx-react";
import clsx from 'clsx'

interface StellplatzProps {
    store: StellplatzStore
}

export const Stellplatz = observer((props: StellplatzProps) => {

    return <div style={{'margin': '10px'}}>
        <div className={classes.bezeichnung}>Stellplatz {props.store.nummer}</div>
        <div className={clsx(classes.positionierung, classes.ausrichtung)} >
            <TextField className={classes.textfield}
                       value={props.store.text}
                       multiline={true}
                       variant={'outlined'}
                       onChange={e => props.store.setText(e.target.value)}
                       rows={3} style={{'whiteSpace': 'nowrap'}}
            />
        </div>
    </div>
})
