import React, {useEffect, useRef} from "react";
import {useParams} from 'react-router-dom'
import {observer} from "mobx-react";
import classes from "./anzeige.module.scss";
import {useMainStore} from "../hooks/use-main-store";

export const Anzeige = observer(() => {
        const params = useParams<{ nr: string }>()
        const nummer = Number(params.nr)
        const canvasRef = useRef(null);
        const store = useMainStore();
        const text = store.stellplaetze[nummer - 1].text

        useEffect(() => {
            const canvas: HTMLCanvasElement | null = canvasRef.current
            if (canvas) {
                zeichneText(canvas, text)
            }
        }, [text, canvasRef])

        return <div
            className={classes.kopf}>
            <img src={process.env.PUBLIC_URL + "/wkt-logo.png"} className={classes.logo} alt={'WKT'}/>
            <div className={classes.ueberschrift}>Spur: {nummer}</div>

            <canvas className={classes.canvas} ref={canvasRef}>{text}</canvas>
        </div>

    }
)

function zeichneText(canvas: HTMLCanvasElement, text: string) {
    const context = canvas.getContext('2d')
    if (context) {

        const bounds = canvas.getBoundingClientRect()
        const hoehe = bounds.height
        const breite = bounds.width

        canvas.height = hoehe
        canvas.width = breite
        context.font = '10vw Kanit'
        let textbreite = 0
        let zeilen = text?.split('\n') ?? []
        let gesHoehe = 0

        for (let i = 0; i < zeilen.length; i++) {
            const textHoehe = context.measureText(zeilen[i])
            gesHoehe += (textHoehe.actualBoundingBoxAscent + textHoehe.actualBoundingBoxDescent)
            textbreite = Math.max(textbreite,textHoehe.width)
        }

        context.clearRect(0, 0, canvas.width, canvas.height)

        let x = canvas.width / 2
        let y = canvas.height / 2
        context.fillStyle = '#ffffff'
        context.textAlign = 'center';
        context.textBaseline = 'middle';

        context.translate(x, y)
        let skalierungHoehe = Math.min(canvas.width / textbreite, 1)
        let skalierungBreite = Math.min(canvas.height / gesHoehe, 1)

        let skalierung = Math.min(skalierungBreite, skalierungHoehe)

        context.scale(skalierung, skalierung)
        let textY = gesHoehe / -2
        for (let i = 0; i < zeilen.length; i++) {
            const textHoehe = context.measureText(zeilen[i])

            gesHoehe += (textHoehe.actualBoundingBoxAscent + textHoehe.actualBoundingBoxDescent)
            context.fillText(zeilen[i], 0, textY, textbreite)
            textY += textHoehe.actualBoundingBoxAscent + textHoehe.actualBoundingBoxDescent + 100
        }

    }
}
